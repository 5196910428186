// extracted by mini-css-extract-plugin
export var preview = "Preview-module--preview--WY-su";
export var preview__close = "Preview-module--preview__close--KA3QD";
export var preview__content = "Preview-module--preview__content--AkIY0";
export var preview__fullTitle = "Preview-module--preview__fullTitle---lyOP";
export var preview__img = "Preview-module--preview__img--KxfYC";
export var preview__info = "Preview-module--preview__info--TMxGF";
export var preview__info_hide = "Preview-module--preview__info_hide--E1+pH";
export var preview__scrollContainer = "Preview-module--preview__scrollContainer--uYHBf";
export var preview__subTitle = "Preview-module--preview__subTitle--Loeoc";
export var preview__text = "Preview-module--preview__text--5BSGq";
export var preview__textList = "Preview-module--preview__textList--b6ByN";
export var preview__title = "Preview-module--preview__title--9QuhT";
export var preview__title__secondTitle = "Preview-module--preview__title__secondTitle--y8Sf-";
export var preview__titles = "Preview-module--preview__titles--BV4qA";
export var preview__titles_hide = "Preview-module--preview__titles_hide--GATWa";
export var preview__videoIcon = "Preview-module--preview__videoIcon--4G1qK";
export var preview__wrapper = "Preview-module--preview__wrapper--gPxRH";