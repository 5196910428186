// extracted by mini-css-extract-plugin
export var services = "SectionServices-module--services--E0kvr";
export var services__H2 = "SectionServices-module--services__H2--887jI";
export var services__H7 = "SectionServices-module--services__H7--dKdDh";
export var services__img = "SectionServices-module--services__img--DoOD-";
export var services__info = "SectionServices-module--services__info--idcSD";
export var services__infoServ = "SectionServices-module--services__infoServ--2nrS4";
export var services__left = "SectionServices-module--services__left--8e4FF";
export var services__list = "SectionServices-module--services__list--341pG";
export var services__listItem = "SectionServices-module--services__listItem--+lk4L";
export var services__macImg = "SectionServices-module--services__macImg--w0MWL";
export var services__mobImg = "SectionServices-module--services__mobImg--FzUgt";
export var services__planshImg = "SectionServices-module--services__planshImg--nn-A7";
export var services__points = "SectionServices-module--services__points--oChdh";
export var services__right = "SectionServices-module--services__right--C011d";
export var services__rightText = "SectionServices-module--services__rightText--raEnk";
export var services__text = "SectionServices-module--services__text--mO1P2";
export var services__textContent = "SectionServices-module--services__textContent--XWUYc";
export var services__textContent_left = "SectionServices-module--services__textContent_left--4sBfK";
export var services__textContent_right = "SectionServices-module--services__textContent_right--69+JA";
export var services__titleH1 = "SectionServices-module--services__titleH1--GQ5mh";
export var services__titleH7 = "SectionServices-module--services__titleH7--jLdps";