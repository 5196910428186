// extracted by mini-css-extract-plugin
export var header = "SectionHeader-module--header--TRp2R";
export var header__bgBase = "SectionHeader-module--header__bgBase--HRQal";
export var header__container = "SectionHeader-module--header__container--t7L4d";
export var header__dash = "SectionHeader-module--header__dash--MSBpk";
export var header__subTitle = "SectionHeader-module--header__subTitle--pXAVX";
export var header__subTitleText = "SectionHeader-module--header__subTitleText--vl2QL";
export var header__title = "SectionHeader-module--header__title--ptLCK";
export var header__titleH1 = "SectionHeader-module--header__titleH1--f3TPr";
export var header__treangle = "SectionHeader-module--header__treangle--yre08";
export var header__treangles = "SectionHeader-module--header__treangles--NN-E+";
export var header__yellowLine = "SectionHeader-module--header__yellowLine--zZCK6";