import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import * as styles from './Layout.module.scss';

const GTM_SCRIPT = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PPX5CWJ');
`;

const Layout = ({ children }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>Accent</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="description" content="Accent is a Singapore-based IT company that also has offices in the USA, Russia and theUK." />
      <meta name="author" content="Accent" />
      <meta name="keywords" content="Accent, IT company, web development, mobile app development, software development" />
      <meta name="news_keywords" content="Accent, IT company" />
      <meta property="og:title" content="Accent" />
      <meta
        property="og:description"
        content="Accent is a Singapore-based IT company that also has offices in the USA, Russia and theUK."
      />
      <meta property="og:type" content="website" />
      <meta property="og:image" content="/page.png" />
      <meta property="og:image:width" content="968" />
      <meta property="og:image:height" content="504" />
      <meta property="og:image:alt" content="Accent, IT company" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:url" content="https://accentdev.com" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:site_name" content="Accent" />
      <meta name="twitter:image:alt" content="Accent, IT company" />
      <meta property="fb:app_id" content="" />
      <meta name="twitter:site" content="https://twitter.com/accent_soft" />
      <meta name="geo.region" content="Asia" />
      <meta name="geo.placename" content="80 Anson road #24-02, Fujixerox towers Singapore 079 907" />
      <script>{GTM_SCRIPT}</script>
    </Helmet>
    <noscript>
      {'<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PPX5CWJ" height="0" width="0" style="display:none;visibility:hidden"></iframe>'}
    </noscript>
    <div className={styles.layout}>{children}</div>
  </>
);

export default memo(Layout);
