// extracted by mini-css-extract-plugin
export var button = "Button-module--button--wnO+-";
export var button__icon = "Button-module--button__icon--12y2T";
export var button__text = "Button-module--button__text--pcIN9";
export var button_active = "Button-module--button_active--9+SFt";
export var button_arrow = "Button-module--button_arrow--nYZpC";
export var button_bordered = "Button-module--button_bordered--YjFHd";
export var button_disabled = "Button-module--button_disabled--a5cjM";
export var button_left = "Button-module--button_left--41eRf";
export var button_light = "Button-module--button_light--gMhCK";
export var button_plus = "Button-module--button_plus--sGqqX";
export var button_right = "Button-module--button_right--btwNm";