// extracted by mini-css-extract-plugin
export var active = "SectionContactUs-module--active--N7yhI";
export var contact = "SectionContactUs-module--contact--bYKUF";
export var contact__container = "SectionContactUs-module--contact__container--HOHO0";
export var contact__image_container = "SectionContactUs-module--contact__image_container--mCDgk";
export var contact__image_container_active = "SectionContactUs-module--contact__image_container_active--nO28P";
export var contact__point = "SectionContactUs-module--contact__point--3fyUm";
export var contact__slider = "SectionContactUs-module--contact__slider--hybiD";
export var contact__sliderRus = "SectionContactUs-module--contact__slider-rus--A+aEc";
export var contact__sliderSin = "SectionContactUs-module--contact__slider-sin--Z187f";
export var contact__sliderUsa = "SectionContactUs-module--contact__slider-usa--L2IZC";
export var contact__slider__dash = "SectionContactUs-module--contact__slider__dash--R6naE";
export var contact__slider_btn = "SectionContactUs-module--contact__slider_btn--pC+CT";
export var contact__slider_info = "SectionContactUs-module--contact__slider_info--wxR0N";
export var contact__slider_item = "SectionContactUs-module--contact__slider_item--NMHJa";
export var contact__slider_items = "SectionContactUs-module--contact__slider_items--AhJvY";
export var contact__slider_mobile = "SectionContactUs-module--contact__slider_mobile--mQyv3";
export var contact__slider_mobile_btn = "SectionContactUs-module--contact__slider_mobile_btn--Y00Sv";
export var contact__slider_mobile_btn_active = "SectionContactUs-module--contact__slider_mobile_btn_active--jnE2j";
export var contact__slider_nav = "SectionContactUs-module--contact__slider_nav--vW0MT";
export var contact__slider_nav_item = "SectionContactUs-module--contact__slider_nav_item--Fg3Zl";
export var contact__slider_nav_item_active = "SectionContactUs-module--contact__slider_nav_item_active--7t-mV";
export var contact__slider_nav_wrapper = "SectionContactUs-module--contact__slider_nav_wrapper--QxLvQ";
export var contact__slider_text = "SectionContactUs-module--contact__slider_text--8Jbws";
export var contact__slider_text_wrapper = "SectionContactUs-module--contact__slider_text_wrapper--xp1oq";
export var contact__title = "SectionContactUs-module--contact__title--propm";