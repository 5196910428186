import React, { memo, useState } from 'react';
import { scroller } from 'react-scroll';
import Scrollspy from 'react-scrollspy';
import cn from 'classnames';
import Icon from 'src/components/ui/Icon';

import logo from 'src/assets/images/svg_logo.svg';
import Mail from 'src/assets/images/svg_mail.svg';
import * as styles from './Sidenav.module.scss';

const links = [
  { text: 'Home', href: 'home' },
  { text: 'About us', href: 'about' },
  { text: 'Services', href: 'services' },
  { text: 'Work', href: 'work' },
  { text: 'Contacts', href: 'contacts' },
];

const Sidenav = ({ onMailClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className={cn(styles.sidenav, isOpen && styles.sidenav_open)}>
      <button
        aria-label="logo"
        type="button"
        className={styles.sidenav__logo}
        onMouseDown={(e) => e.preventDefault()}
        onClick={() => scroller.scrollTo('home', { smooth: 1 })}
      >
        <Icon src={logo} className={styles.sidenav__logoImg} />
      </button>
      <div
        className={styles.sidenav__menu}
        onMouseEnter={() => setIsOpen(true)}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Scrollspy
          items={links.map((item) => item.href)}
          className={styles.sidenav__menuList}
          currentClassName={styles.sidenav__menuItem_active}
          offset={-600}
        >
          {links.map(({ href, text }) => (
            <button
              type="button"
              className={styles.sidenav__menuItem}
              key={href}
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => scroller.scrollTo(href, { smooth: 1 })}
            >
              <span className={styles.sidenav__text}>{text}</span>
            </button>
          ))}
        </Scrollspy>
      </div>
      <button
        aria-label="contact us"
        type="button"
        onClick={onMailClick}
        className={styles.sidenav__mail}
        onMouseOver={() => setIsOpen(true)}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <div className={styles.sidenav__mailImg}>
          <Icon src={Mail} />
          <span className={styles.sidenav__text}>Write us</span>
        </div>
      </button>
    </nav>
  );
};

export default memo(Sidenav);
