import React, {
  useCallback, useState, memo,
} from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import cn from 'classnames';

import Preview from 'src/components/ui/Preview';
import Modal from 'src/components/ui/Modal';
import VideoPlayer from 'src/components/ui/VideoPlayer';

import cards from './cards';
import * as styles from './SectionCards.module.scss';

const SectionCards = ({ isTablet }) => {
  const [videoIsOpen, setVideoIsOpen] = useState(false);
  const [videoItem, setVideoItem] = useState(null);
  const [textIsOpen, setTextIsOpen] = useState(false);
  const [textItem, setTextItem] = useState(null);

  const handleSetTextIsOpen = useCallback(
    (item) => () => {
      setTextItem(item);
      setTextIsOpen(true);
    },
    [setTextItem, setTextIsOpen],
  );

  const handleSetVideoSrc = useCallback(
    (video) => () => {
      setVideoItem(video);
      setVideoIsOpen(true);
    },
    [setTextItem, setTextIsOpen],
  );

  return (
    <div className={styles.sectionCards} id="sectionCards">
      <Controller>
        <Scene triggerElement="#sectionCards" triggerHook={1}>
          {(progress) => (
            <div
              className={cn(
                styles.sectionCards__cards,
                progress === 1 && !isTablet && styles.sectionCards__cards_fixed,
              )}
            >
              {cards.map((item) => (item.type !== 'link' ? (
                <Preview
                  {...item.props}
                  key={item.id}
                  className={styles.sectionCards__card}
                  isVideo={item.type === 'video'}
                  onlyEvent={item.type === 'video' || isTablet}
                  onInteract={
                    item.type === 'video'
                      ? handleSetVideoSrc(item.value)
                      : handleSetTextIsOpen(item.props)
                  }
                />
              ) : (
                <a href={item.value} className={styles.sectionCards__card} key={item.id} target="_dash">
                  <Preview {...item.props} onlyEvent />
                </a>
              )))}
            </div>
          )}
        </Scene>
      </Controller>

      {textIsOpen && textItem && (
        <Modal onClose={() => setTextIsOpen(false)}>
          <div className={styles.sectionCards__modalCard}>
            <h3 className={styles.sectionCards__cardTitle}>{textItem.fullTitle}</h3>
            {textItem.contentList.map((item) => (
              <p key={item} className={styles.sectionCards__cardText}>
                {item}
              </p>
            ))}
          </div>
        </Modal>
      )}

      {videoIsOpen && videoItem && (
        <Modal onClose={() => setVideoIsOpen(false)}>
          <VideoPlayer id={videoItem.id} image={videoItem.img} />
        </Modal>
      )}
    </div>
  );
};

export default memo(SectionCards);
