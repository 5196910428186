import React, {
  forwardRef, useState, useRef, useCallback, useEffect,
} from 'react';
import cn from 'classnames';
import { Scene, Controller } from 'react-scrollmagic';
import FadeScene from 'src/components/ui/FadeScene';
import * as styles from './SectionServices.module.scss';

import mac from '../../assets/images/mac.png';
import mob from '../../assets/images/mob.png';
import plansh from '../../assets/images/plansh.png';

const ITEMS = [
  {
    id: '0',
    title: 'Web development',
    description: [
      'Interactive promo-pages for target audience',
      'Corporate web sites and portals for large numbers of users',
      'Sustainable integrations with customer systems',
    ],
    position: 'right',
    img: mac,
    type: 'mac',
  },
  {
    id: '1',
    title: 'Mobile app development',
    description: [
      'For growing businesses and startups',
      'Complex inside - easy to use',
      'From analytics and design to development and maintenance',
    ],
    position: 'left',
    img: mob,
    type: 'mob',
  },
  {
    id: '2',
    title: 'Software development',
    description: [
      'Custom CRM, ERP, LMS',
      'Tailored for your business',
      'Rapid deployment and seamless integration',
    ],
    position: 'right',
    img: plansh,
    type: 'plansh',
  },
];

const SectionServices = (_, ref) => {
  const [sectionHeight, setSectionHeight] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);
  const sectionRef = useRef(null);

  const handleResize = useCallback(() => {
    setWindowHeight(document.documentElement.clientHeight);
    if (sectionRef.current) {
      const { scrollHeight, offsetHeight } = sectionRef.current;
      setSectionHeight(Math.max(scrollHeight, offsetHeight));
    }
  }, [setSectionHeight, sectionRef]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleResize();
    window.addEventListener('resize', handleResize);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', handleResize);
  }, [sectionRef, setSectionHeight]);

  return (
    <Controller>
      <Scene triggerHook="onEnter" duration={sectionHeight} offset={0}>
        {(progress) => {
          const pxProgress = progress * sectionHeight;
          return (
            <div className={styles.services} ref={ref}>
              <FadeScene
                tag="span"
                scrollPosition={pxProgress}
                windowHeight={windowHeight}
                sectionHeight={sectionHeight}
              >
                <div className={styles.services__titleH1}>Our Skillset</div>
                <p className={styles.services__titleH7}>
                  We write code, design interfaces, research users, test hypotheses,
                  and launch MVPs. Teams work according to Scrum, Waterfall or Agile methodologies.
                  We work best with Java, Phyton, PHP, JavaScript, Vue.js and React.js.
                  We create mobile apps on iOS and Android.
                </p>
              </FadeScene>
              <div className={styles.services__info}>
                {ITEMS.map((item) => (
                  <div className={styles.services__infoServ} key={item.id}>
                    <Controller>
                      <Scene triggerHook="onEnter" duration={windowHeight} offset={200}>
                        {(p) => (
                          <div
                            style={{
                              transform: `translateX(calc(${
                                item.position === 'left' ? '+' : '-'
                              }200px * (1 - ${p})))`,
                            }}
                            className={cn(
                              styles.services__img,
                              item.position === 'left' ? styles.services__right : styles.services__left,
                              styles[`services__${item.type}Img`],
                            )}
                          >
                            <img src={item.img} alt={item.description} />
                          </div>
                        )}
                      </Scene>
                    </Controller>
                    <FadeScene
                      className={cn(
                        styles[`services__${item.position}`],
                        styles.services__textContent,
                        styles[`services__textContent_${item.position}`],
                      )}
                      tag="span"
                      scrollPosition={pxProgress}
                      windowHeight={windowHeight}
                      sectionHeight={sectionHeight}
                    >
                      <div
                        className={cn(
                          styles.services__H2,
                          styles.services__text,
                          item.position === 'left' ? styles.services__rightText : '',
                        )}
                      >
                        {item.title}
                      </div>
                      <ul className={cn(styles.services__H7, styles.services__text, styles.services__list, item.position === 'left' ? styles.services__rightText : '')}>
                        {item.description.map((el) => (
                          <li key={el} className={styles.services__listItem}>{el}</li>
                        ))}
                      </ul>
                    </FadeScene>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Scene>
    </Controller>
  );
};

export default forwardRef(SectionServices);
