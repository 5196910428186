// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--LZE0a";
export var footer__copir = "Footer-module--footer__copir--pSqXt";
export var footer__copirSec = "Footer-module--footer__copirSec---1zdU";
export var footer__dash = "Footer-module--footer__dash--bHG3p";
export var footer__link = "Footer-module--footer__link--uvdJb";
export var footer__mail = "Footer-module--footer__mail--YWjib";
export var footer__mailSec = "Footer-module--footer__mailSec--zcM6g";
export var footer__mail_svg = "Footer-module--footer__mail_svg--nwIEP";
export var footer__section = "Footer-module--footer__section--YWTrn";
export var footer__socSec = "Footer-module--footer__socSec--bipN3";
export var footer__svg = "Footer-module--footer__svg--1nzMy";
export var footer__text = "Footer-module--footer__text--q1OIP";