import React, { useState } from 'react';
import cn from 'classnames';
import TextSelection from 'src/components/ui/TextSelection';
import Modal from 'src/components/ui/Modal';
import ContactUsForm from 'src/components/ContactUsForm';

import pinMapButton from 'src/assets/images/svg_pin_button.svg';
import * as styles from './SectionContactUs.module.scss';

import singapore from '../../assets/images/singapore.png';
import Icon from '../ui/Icon';

const data = [
  {
    id: 'sing',
    country: 'Singapore',
    src: singapore,
    text: [
      '80 Anson road #24-02',
      'Fujixerox towers Singapore 079 907',
    ],
    mapPlace: 'https://www.google.ru/maps/place/Fuji+Xerox+Towers+-+Offices/@1.2732062,103.8435912,21z/data=!4m8!1m2!2m1!1s80+Anson+road+%2324-02+Fujixerox+towers+Singapore+079+907!3m4!1s0x0:0x36cfdd83ef080138!8m2!3d1.2732149!4d103.8436358',
  },
];

const SectionContactUs = ({ isTablet, showContactUs }) => {
  const [isOpenForm, setIsOpenForm] = useState(false);

  return (
    <div className={styles.contact}>
      <div className={styles.contact__container}>

        {data.map(({ src }) => (
          <div
            key={src}
            className={cn(
              styles.contact__image_container,
              styles.contact__image_container_active,
            )}
            style={{ display: 'block', backgroundImage: `url(${src})` }}
          />
        ))}
        <div className={styles.contact__slider}>
          {!isTablet && (
          <div className={styles.contact__slider_nav}>
            {data.map(({ country }) => (
              <TextSelection
                key={country}
                theme="gray"
                className={styles.contact__slider_nav_wrapper}
                withHover
              >
                <div
                  className={cn(
                    styles.contact__slider_nav_item,
                    styles.contact__slider_nav_item_active,
                  )}
                >
                  {country}
                </div>
              </TextSelection>
            ))}
          </div>
          )}
          <div className={styles.contact__slider_items}>
            {data.map(({ text, country }) => (
              <div
                key={country}
                className={styles.contact__slider_item}
                style={{ display: 'block' }}
              >
                <div className={styles.contact__slider_text_wrapper}>
                  {isTablet
                  && <div className={styles.contact__slider_nav_item}>{country}</div>}
                  <div className={styles.contact__slider__dash} />
                  <p className={styles.contact__slider_text}>
                    {text.map((elem) => (
                      <React.Fragment key={elem}>
                        {elem}
                        <br />
                        {' '}
                      </React.Fragment>
                    ))}
                  </p>
                  <a href="mailto:contact@accentdev.com" className={styles.contact__slider_info}>contact@accentdev.com</a>

                  {!isTablet && (
                    <button
                      className={styles.contact__slider_btn}
                      onClick={showContactUs}
                      type="button"
                    >
                      Contact us
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        {data.map(({ mapPlace }) => (
          <a
            key={mapPlace}
            target="_blank"
            href={mapPlace}
            className={cn(
              styles.contact__point,
              styles.contact__point_active,
            )}
            style={{ display: 'block' }}
            rel="noreferrer"
          >
            <Icon src={pinMapButton} />
          </a>
        ))}
      </div>
      {isOpenForm && (
        <Modal onClose={() => setIsOpenForm(false)}>
          <ContactUsForm />
        </Modal>
      )}
    </div>
  );
};

export default SectionContactUs;
