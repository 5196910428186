// extracted by mini-css-extract-plugin
export var sidenav = "Sidenav-module--sidenav--aFCJj";
export var sidenav__link = "Sidenav-module--sidenav__link--ITxLn";
export var sidenav__logo = "Sidenav-module--sidenav__logo--9W91R";
export var sidenav__logoImg = "Sidenav-module--sidenav__logoImg--RMSda";
export var sidenav__mail = "Sidenav-module--sidenav__mail--w-oxg";
export var sidenav__mailImg = "Sidenav-module--sidenav__mailImg--Q22Jx";
export var sidenav__menu = "Sidenav-module--sidenav__menu--qi8Wv";
export var sidenav__menuItem = "Sidenav-module--sidenav__menuItem--y3apa";
export var sidenav__menuItem_active = "Sidenav-module--sidenav__menuItem_active--ypCfU";
export var sidenav__menuList = "Sidenav-module--sidenav__menuList--jIGG6";
export var sidenav__text = "Sidenav-module--sidenav__text--1+3mk";
export var sidenav_open = "Sidenav-module--sidenav_open--S+PuG";