import React, {
  useEffect, useCallback, useState, useRef, memo,
} from 'react';
import cn from 'classnames';

import * as styles from './SectionHeader.module.scss';

const getWindowSize = () => {
  const { clientHeight, clientWidth } = document.documentElement;

  return {
    width: clientWidth,
    height: clientHeight,
  };
};

const SectionHeader = () => {
  const [mousePosition, setMousePosition] = useState({ left: 0, top: 0 });
  const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
  const [sectionWidth, setSectionWidth] = useState('100%');
  const sectionRef = useRef(null);

  const handleResize = useCallback(() => {
    if (typeof document !== 'undefined') setWindowSize(getWindowSize());
    if (sectionRef.current) {
      setSectionWidth(`${sectionRef.current.clientWidth}px`);
    }
  }, []);

  const paralaxEvent = useCallback(
    (e) => setMousePosition({
      left: (e.clientX * 100) / windowSize.width,
      top: (e.clientY * 100) / windowSize.height,
    }),
    [setMousePosition, windowSize],
  );

  const getOffsetStyle = useCallback((value, sensitivity, offset) => (
    +windowSize.width > 319
      ? { [value]: `${mousePosition[value] / sensitivity + offset}%` }
      : {}
  ), [windowSize, mousePosition]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('mousemove', paralaxEvent);
    return () => window.removeEventListener('mousemove', paralaxEvent);
  }, [paralaxEvent]);

  return (
    <section className={styles.header} onLoad={paralaxEvent} ref={sectionRef}>
      <div className={styles.header__container} style={{ width: sectionWidth }}>
        <div className={styles.header__bgBase} />
        <div className={cn(styles.header__yellowLine)} style={getOffsetStyle('left', 10, 57)} />
        <div
          className={styles.header__treangle}
          style={{
            ...getOffsetStyle('left', 5, 45),
            ...getOffsetStyle('top', 10, 50),
          }}
        />
        <div
          className={styles.header__treangles}
          style={{
            ...getOffsetStyle('left', 19, 47),
            ...getOffsetStyle('top', 25, 49),
          }}
        />
        <div className={styles.header__title}>
          <h1 className={styles.header__titleH1}>
            The best custom software
            <div> for your business</div>
          </h1>
          <h2 className={styles.header__subTitle}>
            <span className={styles.header__subTitleText}>Software Development</span>
            <span className={styles.header__dash} />
            <span className={styles.header__subTitleText}>Web Development</span>
            <span className={styles.header__dash} />
            <span className={styles.header__subTitleText}>Mobile Apps Development</span>
          </h2>
        </div>
      </div>
    </section>
  );
};

export default memo(SectionHeader);
